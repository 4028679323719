// Variables

$container-max-widths: (
  sm: 767px,
  md: 991px,
  lg: 1195px,
  xl: 1196px,
);

$border-radius: 0.1875rem;
