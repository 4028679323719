.cky-btn {
  border-radius: 3px !important;
  width: 100% !important;
  font-weight: 900 !important;
  font-size: 0.875rem !important;
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
  line-height: 1 !important;
  color: #2d2d2d !important;
  border: 1px solid rgb(224, 228, 232) !important;
}

.cky-btn:hover {
  border-color: transparent;
  filter: brightness(1.07);
  text-decoration: none;
  outline: 0;
  box-shadow: 0px 24px 32px rgba(45, 45, 45, 0.16) !important;
}

.cky-btn.cky-btn:focus-visible {
  border-color: #2d2d2d !important;
  box-shadow: 0 0 0 1px #2d2d2d;
  outline: 0;
}

@supports not selector(:focus-visible) {
  .cky-btn.cky-btn:focus {
    border-color: #2d2d2d !important;
    box-shadow: 0 0 0 1px #2d2d2d;
    outline: 0;
  }
}

.cky-btn.cky-btn-accept {
  background: rgb(255, 230, 0) !important;
  border: 1px solid #ffe600 !important;
}

.cky-btn.cky-btn-accept:active {
  background: #ffd200 !important;
}

.cky-consent-container {
  width: 360px !important;
  max-width: 100%;
  border-radius: 3px !important;
}

.cky-notice .cky-title {
  margin-bottom: 1rem !important;
  font-size: 1.5rem !important;
  line-height: 1.25 !important;
  font-weight: 900 !important;
}

.cky-consent-container .cky-consent-bar {
  padding: 1rem !important;
}
.cky-notice-group .cky-notice-btn-wrapper,
.cky-footer-wrapper .cky-prefrence-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

.cky-btn-accept {
  order: 1 !important;
}
.cky-btn-preferences,
.cky-btn-reject {
  order: 2 !important;
}
.cky-btn-preferences:active,
.cky-btn-reject:active,
.cky-btn-customize:active {
  border-color: #2d2d2d !important;
  box-shadow: 0 0 0 1px #2d2d2d !important;
}
.cky-btn-customize {
  order: 3 !important;
}

.cky-preference-header .cky-preference-title {
  font-weight: 900 !important;
  font-size: 24px !important;
  line-height: 30px !important;
  padding-top: 0;
  text-transform: uppercase;
}
.cky-accordion-header .cky-accordion-btn {
  font-size: 18px;
}

.cky-preference-body-wrapper .cky-preference-content-wrapper p,
.cky-accordion-header-des p {
  font-size: 1rem;
}

.cky-notice-des * {
  font-size: 18px !important;
  line-height: 27px;
}
.cky-accordion {
  border-bottom: none !important;
}

.cky-notice-des {
  padding: 0 !important;
  padding-bottom: 1rem !important;
  max-height: 95vh !important;
  overflow-y: visible !important;
  border-bottom: 1px solid #e0e4e8;
}

.cky-preference-header {
  border-bottom: none !important;
  padding: 0 1rem !important;
}
.cky-preference-body-wrapper {
  padding: 0 1rem !important;
}
.cky-modal .cky-preference-center {
  padding: 1rem 0 !important;
}
.cky-prefrence-btn-wrapper {
  padding: 1rem 1rem 0 1rem !important;
  border-top: 1px solid #e0e4e8;
}

.cky-footer-shadow {
  display: none !important;
}
.cky-modal {
  border-radius: 3px !important;
}
@media only screen and (min-width: 768px) {
  .cky-consent-container {
    width: 720px !important;
  }
  .cky-modal .cky-preference-center {
    width: 720px;
    padding: 4rem 0 !important;
  }
  .cky-preference-body-wrapper {
    padding: 0 4rem !important;
  }
  .cky-btn {
    width: auto !important;
  }

  .cky-prefrence-btn-wrapper {
    display: block;
    width: auto;
    padding: 2rem 4rem 0 4rem !important;
  }
  .cky-preference-header .cky-preference-title {
    font-weight: 900 !important;
    font-size: 40px !important;
    line-height: 50px !important;
  }
  .cky-preference-center {
    max-height: 95vh !important;
  }
  .cky-consent-container .cky-consent-bar {
    padding: 4rem !important;
  }

  .cky-notice-btn-wrapper {
    margin-top: 2rem !important;
  }
  .cky-notice-des {
    padding-bottom: 2rem !important;
  }
  .cky-preference-header {
    padding: 0 64px 22px 64px !important;
  }
  .cky-btn-accept,
  .cky-btn-reject,
  .cky-btn-customize,
  .cky-btn-preferences {
    order: unset !important;
  }
}
@media only screen and (max-width: 440px) {
  .cky-notice .cky-title {
    padding: 0 !important;
  }
}

@media print {
  .cky-overlay,
  .cky-consent-container,
  .cky-modal {
    display: none !important;
  }
}
